<template>
        <div class="loginPanel">
                <div class="bigText">Адрес электронной почты был успешно подтвержден.</div>
                <buttonNone class="bclose mt30" text='Авторизоваться' @clickButton="gotoAutorize"></buttonNone>
        </div>
</template>

<script>

import ButtonNone from "../../common/ButtonNone";

export default {
        props: ["email", "error", "back"],
        components: {ButtonNone},
        data: () => {
                return {}
        },

        methods: {
                gotoAutorize() {
                        window.history.pushState({}, null, "/");
                        this.$emit("gotoAutorize");
                }
        }
}
</script>
