<template>
        <div class="horFlex" v-if="mode != null" v-show="vie">
                <div class="vertFlex">
                        <div class="regFiled">
                                <div class="titleProd">
                                        <div class="logo"/>
                                        <div class="logoname">Интерактивный школьный атлас</div>
                                </div>

                                <div class="aboutProd">
                                        <span class="redcolortext">Цифровой атлас —</span>
                                        современное средство
                                        обучения географии
                                </div>
                                <div class="forProd">Для интерактивной доски и персонального компьютера</div>
                                <buttonRed class="bstart" text='Начать пользоваться' @clickButton="showDlg"></buttonRed>

                                <Dialog v-model:visible="viewdlg" modal :closable="mode !== 'updatePasswordOk'" style="width: 574px" class="logindlg" header=" ">
                                        <login v-if="mode === 'login'" @register="gotoRegister" @getpass="getpass" @error="gotoError" ></login>
                                        <register v-if="mode === 'register'" @login="gotoLogin" @registerOk="registerOk" @police="police" @ifuse="ifuse" @error="gotoError"></register>
                                        <forgotPassword v-if="mode === 'forgotPassword'" @login="gotoLogin" @forgotPasswordOk="forgotPasswordOk" @error="gotoError"></forgotPassword>
                                        <registerOk v-if="mode === 'registerOk'" @gotoLogin="gotoLogin" :email="fpemail"></registerOk>
                                        <forgotPasswordOk v-if="mode === 'forgotPasswordOk'" :email="fpemail"></forgotPasswordOk>
                                        <updatePassword v-if="mode === 'updatePassword'" :forgot="codeForgot" @updatePasswordOk="updatePasswordOk"></updatePassword>
                                        <updatePasswordOk v-if="mode === 'updatePasswordOk'" :email="fpemail"></updatePasswordOk>
                                        <ifUse v-if="mode === 'ifuse'" @gotoRegitster="gotoRegister"></ifUse>
                                        <police v-if="mode === 'police'" @gotoRegitster="gotoRegister"></police>
                                        <error v-if="mode === 'error'" :error="errorMessage" :email="fpemail" :back="back" @gotoLogin="gotoLogin" @gotoBack="gotoBack"></error>
                                        <SuccessConfirm v-if="mode === 'successConfirm'" @gotoAutorize="() => mode = 'login'"></SuccessConfirm>
                                        <SuccessConfirmDouble v-if="mode === 'SuccessConfirmDouble'" @gotoAutorize="() => mode = 'login'"></SuccessConfirmDouble>
                                </Dialog>

                                <Dialog v-model:visible="viewSupport" modal style="width: 680px" class="supportdlg" header=" ">
                                        <div class="header">Служба поддержки</div>
                                        <div class="simple">
                                                По вопросам, связанным с работой цифрового атласа, обращайтесь по
                                                телефону +7 (499) 302-17-32 или по электронной почте:
                                                <ul>
                                                        <li>картографическая поддержка – <a href="mailto:map@school-atlas.ru">map@school-atlas.ru</a>;</li>
                                                        <li>методическая поддержка – <a href="mailto:metod@school-atlas.ru">metod@school-atlas.ru</a>;</li>
                                                        <li>техническая поддержка – <a href="mailto:support@school-atlas.ru">support@school-atlas.ru</a>.</li>
                                                </ul>
                                        </div>
                                </Dialog>
                        </div>
                </div>

                <iframe class="frame_reclam" :src="currentReclamPath"></iframe>

                <div class="btn_telegram" @click="execTelegram"></div>
                <div class="btn_vk" @click="execVK"></div>
                <div class="btn_support" @click="execSupport">Служба поддержки</div>

                <div class="bkimageF">
                        <div class="bkimage"></div>
                </div>


                <div class="buttonsLR">
                        <div class="bleft" @click="prevReclam"></div>
                        <div class="bright" @click="nextReclam"></div>
                </div>
        </div>
</template>

<script>

import Login from "./register/Login.vue"
import Register from "./register/Register.vue"
import RegisterOk from "./register/RegisterOk.vue"
import ForgotPassword from "./register/ForgotPassword.vue"
import ForgotPasswordOk from "./register/ForgotPasswordOk.vue"
import UpdatePassword from "./register/UpdatePassword.vue"
import updatePasswordOk from "./register/UpdatePasswordOk"
import IfUse from "./register/IfUse.vue"
import Police from "./register/Police.vue"
import Error from "./register/Error.vue"
import SuccessConfirm from "./register/SuccessConfirm.vue"
import SuccessConfirmDouble from "./register/SuccessConfirmDouble.vue"
import ButtonRed from "../common/ButtonRed"

export default {
        components: {
                Login,
                Register,
                RegisterOk,
                ForgotPassword,
                ForgotPasswordOk,
                UpdatePassword,
                IfUse,
                Police,
                Error,
                SuccessConfirm,
                SuccessConfirmDouble,
                updatePasswordOk,
                ButtonRed
        },

        data: function () {
                return {
                        mode: null,
                        back: null,
                        fpemail: null,
                        errorMessage: null,
                        reclamPathIdx : 0,
                        viewdlg : false,
                        codeForgot : null,
                        vie : false,
                        typeError : '',
                        viewSupport: false
                }
        },

        mounted() {
                document.fonts.ready.then( () => this.vie = true)

                try {
                        if (forgot && forgot !== "{{forgot}}") {
                                this.codeForgot = forgot;
                                this.mode = "updatePassword";
                                this.viewdlg = true;
                                return;
                        }
                } catch(e) {}

                try {
                        if (confirm === "1") {
                                this.mode = "successConfirm";
                                this.viewdlg = true
                        }
                } catch(e) {}

                try {
                        if (confirmDouble === "1") {
                                this.mode = "SuccessConfirmDouble";
                                this.viewdlg = true
                        }
                } catch(e) {}

                try {
                        let em = null
                        let emP = null
                        try{em = email} catch(e) {}
                        try{emP = emailPost} catch(e) {}
                        if (error &&  error !== "{{error}}") {
                                this.mode = "error";
                                this.errorMessage = {message : error, property:'wait', email : em, emailPost : emP}
                                this.viewdlg = true
                        }
                } catch(e) {}

                if (this.profile) {
                        this.mode = "profile";
                }
                this.mode = this.mode || "login";
        },

        computed : {
                currentReclamPath() {
                        return reclamPath[this.reclamPathIdx] + `?w=${window.innerWidth}&h=${window.innerHeight}`
                }
        },

        methods: {
                showDlg() {
                        this.mode = "login";
                        this.viewdlg = true
                },
                gotoRegister() {
                        this.mode = "register";
                },
                gotoLogin() {
                        this.mode = "login";
                },
                registerOk(email) {
                        this.fpemail = email;
                        this.mode = "registerOk";
                },
                updatePasswordOk(email) {
                        this.fpemail = email;
                        this.mode = "updatePasswordOk";
                },
                getpass() {
                        this.mode = "forgotPassword";
                },
                forgotPasswordOk(email) {
                        this.fpemail = email;
                        this.mode = "forgotPasswordOk";
                },
                ifuse() {
                        this.mode = "ifuse";
                },
                police() {
                        this.mode = "police";
                },
                gotoError(errorM, email) {
                        this.fpemail = email;
                        this.errorMessage = errorM;
                        this.back = this.mode;
                        this.mode = "error";
                },
                gotoBack() {
                        this.mode = this.back;
                        this.back = null;
                },
                prevReclam() {
                        this.reclamPathIdx--;
                        if(this.reclamPathIdx < 0) this.reclamPathIdx += reclamPath.length
                },
                nextReclam() {
                        this.reclamPathIdx++;
                        if(this.reclamPathIdx >= reclamPath.length) this.reclamPathIdx -= reclamPath.length
                },
                errorConfirmSuccess() {
                        this.mode = 'SuccessConfirmDouble'
                },
                execTelegram() {
                        window.open('https://t.me/astpress_school', 'target=_blank');
                },
                execVK() {
                        window.open('https://vk.com/schoolatlas', 'target=_blank');
                },
                execSupport() {
                        this.viewSupport = true
                }
        }
}

</script>
