<template>
        <div class="loginPanel">
                <div class="messageText">Проверьте e-mail:</div>
                <div class="messageText messageTextRed mt10">{{ email }}</div>
                <div class="messageText mt10">Если он зарегистрирован, вы получите письмо с подробной информацией.</div>
        </div>
</template>

<script>

export default {
        props: ["email"],
        data: function () {
                return {}
        },

        methods: {}
}
</script>
