<template>
        <div class="loginPanel">
                <div class="bigText" v-html="errorMessage"></div>
                <buttonRed class="bclose mt30" v-if="wait && error.email" text='Отправить письмо повторно' @clickButton="sendPost" :disable="timeout > 0"></buttonRed>
                <div v-if="timeout > 0" class="mt10" style="text-align:center">Попробуйте снова через {{ viewTimeout }}</div>
                <!--buttonNone class="bclose mt30" text='Продолжить' @clickButton="gotoLogin"></buttonNone-->
        </div>
</template>

<script>

import buttonRed from "../../common/ButtonRed.vue";
import g from "../../globals";

export default {
        props: ["email", "error", "back", "typeError"],
        components: {buttonRed},
        data: function () {
                return {
                        errorMessage: "",
                        wait: false,
                        timeout : 0
                }
        },

        computed : {
                viewTimeout() {
                        let min = Math.floor(this.timeout / 60)
                        let sec = this.timeout - min*60
                        let out = ""
                        switch(min) {
                                case 1: out = `1 минуту`; break;
                                case 2:
                                case 3:
                                case 4: out = `${min} минуты`; break;
                                case 5: out = "5 минут"; break;
                        }
                        switch(sec) {
                                case 0: return out
                                case 1: case 21: case 31: case 41: case 51: return out += ` ${sec} секунду`
                                case 2: case 3: case 4:
                                case 22: case 23: case 24:
                                case 32: case 33: case 34:
                                case 42: case 43: case 44:
                                case 52: case 53: case 54: return out += ` ${sec} секунды`;
                                default: return out += ` ${sec} секунд`;
                        }
                }
        },

        mounted() {
                if (typeof (this.error) == "object") {
                        this.errorMessage = this.error.message;
                        this.wait = (this.error.property === "wait");
                } else {
                        this.errorMessage = this.error;
                }
        },

        methods: {
                gotoLogin() {
                        if (!this.back) location.href = "/";
                        else this.$emit("gotoBack");
                },

                async sendPost() {
                        if(this.timeout > 0) return
                        this.timeout = 300
                        g.block(true);
                        try {
                                let addr = "postConfirm"
                                if(this.error.emailPost) addr = this.error.emailPost
                                await g.postUrl("auth/" + addr, {email: this.error.email});
                        } catch(e) {
                                if(e.property === "confirmSuccess") this.$emit("confirmSuccess");
                        }
                        g.block(false);
                        let to = setInterval(() => {
                                this.timeout--
                                if(!this.timeout) clearInterval(to)
                        }, 1000)
                }
        }
}
</script>
