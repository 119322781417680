<template>
        <div class="loginPanel">
                <div class="lpTitle">Вход</div>
                <div class="lp1">
                        <div class="lp1_text">Первый раз в системе?</div>
                        <buttonNone text='Зарегистрироваться!' @clickButton="gotoRegitster"></buttonNone>
                </div>
                <input type="text" placeholder="Электронная почта" class="email mt40" v-model="email" @keyup.enter="enter" :class="{error : errfield.indexOf('email') !== -1}"/>
                <!--input type="password" placeholder="Пароль" class="ps" v-model="ps" @keyup.enter="enter" :class="{error : errfield.indexOf('ps') != -1}"/-->
                <inputPassword placeholder="Пароль" ref="ps" @updateps="updateps" @keyup.enter="enter" :class="{error : errfield.indexOf('email') !== -1}"></inputPassword>
                <div class="dvError mt20" :class="{viewError : errorText}">{{ errorText }}</div>
                <buttonRed class="binput mt20 ml50 mr50" text='Войти' @clickButton="login" :disable="isDisable"></buttonRed>
                <buttonNone class="bps mt20" text='Не помню пароль' @clickButton="getpass"></buttonNone>
        </div>
</template>

<script>
import ButtonRed from "../../common/ButtonRed"
import ButtonNone from "../../common/ButtonNone"
import InputPassword from "../../common/InputPassword"
import g from "../../globals";
import {useCookies} from "vue3-cookies"

export default {
        components: {ButtonRed, ButtonNone, InputPassword},

        data: function () {
                return {
                        email: "",
                        pass: "",
                        errorText: "",
                        errfield: []
                }
        },

        computed : {
                isDisable() {
                        console.log(this.email, this.pass)
                        return this.email === '' || this.pass === ''
                }

        },
        watch : {
                email() {
                        this.errfield = []
                        this.errorText = ""
                },
                pass() {
                        this.errfield = []
                        this.errorText = ""
                }
        },
        methods: {
                enter() {
                        this.email = this.email.trim();
                        this.pass = this.pass.trim();
                        if(!this.email || !this.pass) return
                        this.login()
                },

                async login() {
                        try {
                                this.email = this.email.trim();
                                this.pass = this.pass.trim();
                                let data = await g.postUrl("auth/login", {email: this.email, ps: this.pass});
                                g.setStorage("AUTH_TOKEN", "Bearer " + data.accessToken);
//                                let tk = g.getStorage("AUTH_TOKEN");
                                g.removeStorage("state");
                                const {cookies} = useCookies()
                                cookies.remove("afterSave")
                                location.replace("/");
                        } catch (e) {
                                if(e.property === "wait" || e.property === "ban") this.$emit("error", e, this.email);
                                else {
                                        this.errfield = [];
                                        this.errfield.push("email", "ps");
                                        this.errorText = e.message;
                                }
                        }
                },
                gotoRegitster() {
                        this.$emit("register");
                },
                getpass() {
                        this.$emit("getpass");
                },

                updateps() {
                        this.pass = this.$refs.ps.getPS();
                },

        }
}
</script>
