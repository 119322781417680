<template>
	<div class="loginPanel">
                <div class="messageText">Для завершения регистрации подтвердите ваш e-mail. На указанный адрес отправлено письмо с инструкциями.</div>
                <div class="messageText mt10">Если вы не получили письмо, проверьте папку «Спам» или обратитесь в службу поддержки.</div>
                <buttonRed class="bclose mt50" text='Отправить письмо повторно' @clickButton="sendPost" :disable="timeout > 0"></buttonRed>
                <div v-if="timeout > 0" class="mt10" style="text-align:center">Попробуйте снова через {{ viewTimeout }}</div>
        </div>
</template>

<script>
        import buttonRed from "../../common/ButtonRed.vue"
        import g from "../../globals";

	export default {
                props : ["email"],
                components : {buttonRed},

		data : function() {
			return {
                                timeout : 0
			}
		},

                computed : {
                        viewTimeout() {
                                let min = Math.floor(this.timeout / 60)
                                let sec = this.timeout - min*60
                                let out = ""
                                switch(min) {
                                        case 1: out = `1 минуту`; break;
                                        case 2:
                                        case 3:
                                        case 4: out = `${min} минуты`; break;
                                        case 5: out = "5 минут"; break;
                                }
                                switch(sec) {
                                        case 0: return out
                                        case 1: case 21: case 31: case 41: case 51: return out += ` ${sec} секунду`
                                        case 2: case 3: case 4:
                                        case 22: case 23: case 24:
                                        case 32: case 33: case 34:
                                        case 42: case 43: case 44:
                                        case 52: case 53: case 54: return out += ` ${sec} секунды`;
                                        default: return out += ` ${sec} секунд`;
                                }
                        }
                },
                methods : {
                        async sendPost() {
                                if(this.timeout > 0) return
                                this.timeout = 300
                                await g.postUrl("auth/postConfirm", { email: this.email});
                                let to = setInterval(() => {
                                        this.timeout--
                                        if(!this.timeout) clearInterval(to)
                                }, 1000)
                        }
                }
	}
</script>
