<template>
	<div class="loginPanel">
                <div class="messageText">Пароль был успешно изменен.</div>
                <buttonNone class="bclose mt30" text='Авторизоваться' @clickButton="gotoAutorize"></buttonNone>
        </div>
</template>

<script>

        import ButtonNone from "../../common/ButtonNone";

        export default {
	        props : ["email"],
                components : {ButtonNone},
		data : function() {
			return {
			}
		},

                methods : {
                        gotoAutorize() {
                                window.history.pushState({}, null, "/");
                                this.$emit("gotoAutorize");
                        }
                }
	}
</script>
